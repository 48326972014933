import http from "../http-common";

class UserDataService {
  getAll(sessionId) {
    const data = {sessionId: sessionId};
    return http.get("/user", {params: data});
  }
  getSuperAdmins(sessionId) {
    const data = {sessionId: sessionId};
    return http.get("/user/superadmin", {params: data});
  }
  getCurrentUser(sessionId) {
    const data = {sessionId: sessionId};
    return http.get("/user/getCurrent", {params: data});
  }
  async checkAuthStatus(sessionId) {
    const data = {sessionId: sessionId};
    return http.get("/auth",{params: data});
  }
  async getRole(sessionId) {
    const data = {sessionId: sessionId};
    return http.get("/auth/role",{params: data});
  }
  async disconnect(sessionId) {
    const data = {sessionId: sessionId};
    return http.get("/auth/disconnect",{params: data});
  }
  async checkAuth(login, password) {
    const data = {login: login, password: password};
    return http.post("/auth",{},{params: data});
  }

  create(user) {
    const data = {user};
    return http.post("/user/create",{},{params: data});
  }

  save(sessionId, user) {
    const data = {sessionId, user};
    return http.post("/user/save", data);
  }

  delete(sessionId, user) {
    const data = {sessionId: sessionId};
    return http.delete("/user/delete/"+user.uuid,{params: data});
  }
  
}

export default new UserDataService();
