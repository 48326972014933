export default {
  install: (app) => {
    app.config.globalProperties.$displayDate = (date, empty) => {
      if(!date) return empty ? empty : "";
      return new Date(date).toLocaleDateString();
    }

    app.config.globalProperties.$displayDateTime = (date, empty) => {
      if(!date) return empty ? empty : "";
      return new Date(date).toLocaleDateString() + " - " + new Date(date).toLocaleTimeString();
    }

    app.config.globalProperties.$formatBytes = (bytes, decimals = 2) => {
      if (!+bytes) return '0 Octets'
      const k = 1000
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Octets', 'Ko', 'Mo', 'Go', 'To', 'Po']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }
    
    app.config.globalProperties.$shortText = (text, length = 30) => {
      if (text && text.length > length)
        return text.replace(/(\r\n|\n|\r)/gm, "").substring(0,length - 3) + '...';
      return text;
    }

    app.config.globalProperties.$displayFrequency = (frequency) => {
      let r = "";
      if (frequency.d && frequency.d > 0) r+=`${frequency.d} jours `;
      if (frequency.m && frequency.m > 0) r+=`${frequency.m} mois `;
      if (frequency.y && frequency.y > 0) r+=`${frequency.y} ans `;
      return r;
    }
  }
}