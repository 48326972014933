import axios from "axios";

const options = {
  'development':{
    baseURL: "http://localhost:8080", // Origin of requests
    headers: {
      "Content-type": "application/json"
    }
  },
  'production': {
    baseURL: "https://qrmaintenance.fr:8080", // Origin of requests
    headers: {
      "Content-type": "application/json"
    }
  }
}
// TODO change IP address for production
export default axios.create(
  options[process.env.NODE_ENV]
);