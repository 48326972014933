import { createWebHistory, createRouter } from "vue-router";
import UserDataService from "./services/UserDataService";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

const routes =  [

  {
    path: "/qrcode/:uuid",
    name: "QrCode",
    component: () => import("./pages/QrCode")
  },
  {
    path: "/public/",
    component: () => import("./pages/public/App"),
    children: [
      {
        path: "equipement/:uuid",
        name: "Public Equipement",
        component: () => import("./pages/public/Equipement")
      },
      {
        path: "maintenance/:uuid",
        name: "Public Maintenance",
        component: () => import("./pages/public/Maintenance")
      },
      {
        path: "maintenancedone/",
        name: "Public Maintenance Done",
        component: () => import("./pages/public/MaintenanceDone")
      }
    ]
  },
  {
    path: "/",
    alias: "/",
    meta: {
      requiresAuth: false,
      demoAuth : true
    },
    component: () => import("./pages/showcase/App"),
    children: [
      {
        path: "",
        name: "Showcase Index",
        component: () => import("./pages/showcase/Index")
      },
      {
        path: "admin",
        name: "Showcase Admin",
        component: () => import("./pages/showcase/Admin")
      },
      {
        path: "worker",
        name: "Showcase Worker",
        component: () => import("./pages/showcase/Worker")
      },
      {
        path: "workorder",
        name: "Showcase Workorder",
        component: () => import("./pages/showcase/Workorder")
      },
      {
        path: "login",
        name: "Login",
        component: () => import("./pages/showcase/Login")
      },
      {
        path: "register",
        name: "Register",
        component: () => import("./pages/showcase/Register")
      },
      {
        path: "/cgu",
        name: "CGU",
        component: () => import("./pages/CGU")
      },
      {
        path: "/pricing",
        name: "Pricing",
        component: () => import("./pages/showcase/Pricing")
      },
      {
        path: "/ama",
        name: "AMA",
        component: () => import("./pages/showcase/AMA")
      },
    ]
  },
  {
    path: "/dashboard",
    meta: {
      requiresAuth: true,
      roles: ["ADMIN"]
    },
    component: () => import("./pages/backoffice/App"),
    children: [
      {
        path: "",
        name: "Dashboard Index",
        component: () => import("./pages/backoffice/Index"),
      },
      {
        path: "maintenances",
        name: "Dashboard Maintenances",
        component: () => import("./pages/backoffice/Maintenances"),
      },
      {
        path: "maintenances/:uuid",
        name: "Dashboard Maintenance Details",
        component: () => import("./pages/backoffice/MaintenanceDetails"),
      },
      {
        path: "sites",
        name: "Dashboard Sites",
        component: () => import("./pages/backoffice/Sites"),
      },
      {
        path: "equipements",
        name: "Dashboard Equipements",
        component: () => import("./pages/backoffice/Equipements"),
      },
      {
        path: "equipements/:uuid",
        name: "Dashboard Equipement Details",
        component: () => import("./pages/backoffice/EquipementDetails"),
      },
      {
        path: "qrcodes",
        name: "Dashboard QR Codes",
        component: () => import("./pages/backoffice/QrCodes"),
      },
      {
        path: "qrcode/enroll/:uuid",
        name: "QR Code Enrollment",
        component: () => import("./pages/backoffice/QrCodeEnrollment"),
      },
      {
        path: "forms",
        name: "Dashboard Forms",
        component: () => import("./pages/backoffice/Forms"),
      },
      {
        path: "forms/:uuid",
        name: "Dashboard Form Details",
        component: () => import("./pages/backoffice/FormDetails"),
      },
      {
        path: "store",
        name: "Dashboard Shop",
        component: () => import("./pages/backoffice/Store"),
      },
      {
        path: "settings",
        name: "Dashboard Settings",
        component: () => import("./pages/backoffice/Settings"),
      }
    ]
  },
  {
    path: "/maintenance",
    meta: {
      requiresAuth: true,
      roles: ["WORKER"]
    },
    component: () => import("./pages/maintenance/App"),
    children: [
      {
        path: "",
        name: "Maintenance Index",
        component: () => import("./pages/maintenance/Index"),
      },
      {
        path: "maintenances",
        name: "Maintenances List",
        component: () => import("./pages/maintenance/Maintenances"),
      },
      {
        path: "maintenances/new",
        name: "New Maintenance",
        component: () => import("./pages/maintenance/NewMaintenance"),
      },
      {
        path: "maintenances/:uuid",
        name: "Maintenance Details",
        component: () => import("./pages/maintenance/MaintenanceDetails"),
      },
      {
        path: "equipement/:uuid",
        name: "Equipement Details",
        component: () => import("./pages/maintenance/EquipementForms"),
      },
    ]
  },
  { 
    path: '/qradmin', 
    meta: {
      requiresAuth: true,
      roles: ["SUPERADMIN"]
    },
    component: () => import("./pages/admin/App"),
    children: [
      {
        path: "",
        name: "Admin Index",
        component: () => import("./pages/admin/Index"),
      },
      {
        path: "entities",
        name: "Admin Entities",
        component: () => import("./pages/admin/Entities"),
      },
      {
        path: "entities/:uuid",
        name: "Admin Entity Details",
        component: () => import("./pages/admin/EntityDetails"),
      },
      {
        path: "qrcodes",
        name: "Admin QR Codes",
        component: () => import("./pages/admin/QrCodes"),
      },
      {
        path: "orders",
        name: "Admin Orders",
        component: () => import("./pages/admin/Orders"),
      },
      {
        path: "orders/:uuid",
        name: "Admin Order Details",
        component: () => import("./pages/admin/OrderDetails"),
      },
      {
        path: "settings",
        name: "Admin Settings",
        component: () => import("./pages/admin/Settings"),
      },
    ]
  },
  { 
    path: '/:pathMatch(.*)*', 
    component: () => import("./pages/PageNotFound"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Auth Guard
  if (!to.matched.some(record => record.meta.requiresAuth)){
    next();
    return true;
  }
  const sessionId = cookies.get('sessionId');
  try {
    const user = (await UserDataService.checkAuthStatus(sessionId)).data;
    if (!user){
      next({ name:"Login", query: {nextUrl: to.fullPath} });
      return false;
    }
    if(to.meta.roles.includes(user.role)){
      next()
      return true;
    }
    switch(user.role){
      case "ADMIN":
        next({ name:"Dashboard Index"});
        break;
      case "WORKER": 
        next({ name:"Maintenance Index"});
        break;
      case "SUPERADMIN": 
        next({ name:"Admin Index"});
        break;
      default:
        next({ name:"Login", query: {nextUrl: to.fullPath} });
        break;
    }
  }
  catch(err) {
    console.log(err);
    next({
      name:"Login",
      query: {
        nextUrl: to.fullPath
      }
    })
  }
});

export default router;