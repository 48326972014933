<template>
    <RouterView/>
</template>

<script>

export default {
  name: 'QrMaintenance',
  created() {
    document.title = "QR Maintenance";
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
    }
    link.href = require("@/assets/logo.png");
  }
}
</script>

<style>
.svg-white{
    filter: invert(100%) sepia(0%) saturate(100%) brightness(100%) contrast(100%);
}

.svg-red{
    filter: invert(8%) sepia(97%) saturate(6467%) hue-rotate(3deg) brightness(135%) contrast(113%);
}

.svg-green{
    filter: invert(58%) sepia(60%) saturate(613%) hue-rotate(64deg) brightness(89%) contrast(84%);
}

.svg-blue{
    filter: invert(53%) sepia(93%) saturate(907%) hue-rotate(168deg) brightness(98%) contrast(93%);
}

.btn-primary{
    background-color: #27B2F3 !important;
    border-color: #27B2F3 !important;
}

.grecaptcha-badge {
    visibility: hidden;
}
</style>
