import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import router from './router';
import VueCookies from 'vue3-cookies';
import QRMaintenanceLibrary from './global/QRMaintenanceLibrary';

const cookiesParams = {
  expireTimes: "30d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "None"
}

const app = createApp(App);

app.use(router)
app.use(VueCookies, {cookiesParams})
app.use(QRMaintenanceLibrary)
app.mount('#app');

